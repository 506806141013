import { UserIcon } from '@heroicons/react/solid';
import { Link } from 'remix';

import { useUser } from '~/ctx/user';

type NavigationProps = {};

const UserLink = () => {
  const { user } = useUser();

  return (
    <Link to={user ? 'profile' : 'login'} prefetch="intent">
      <UserIcon className="h-5 w-5" />
    </Link>
  );
};

export const Navigation = ({ ...props }: NavigationProps) => {
  return (
    <nav className="flex items-end px-12 xs:px-2 pt-12 pb-16">
      <Link to="/" prefetch="intent">
        <h2>Ghoste the Dev</h2>
      </Link>
      <ul className="flex flex-1 justify-end items-end pl-0 list-none space-x-8">
        <li>
          <Link to="/" prefetch="intent">
            Home
          </Link>
        </li>
        <li>
          <Link to="articles" prefetch="intent">
            Blog
          </Link>
        </li>
        <li>
          <Link to="links" prefetch="intent">
            Links
          </Link>
        </li>
        <li>
          <Link to="projects" prefetch="intent">
            Projects
          </Link>
        </li>
        <li>
          <Link to="contact" prefetch="intent">
            Contact
          </Link>
        </li>
        <li>
          <UserLink />
        </li>
      </ul>
    </nav>
  );
};
