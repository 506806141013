import {
  useContext,
  createContext,
  ReactNode,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { User } from '@supabase/supabase-js';

type UserCtxProps = {
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
};

const UserCtx = createContext<UserCtxProps | undefined>(undefined);

type UserProviderProps = {
  children: ReactNode;
  initialUser?: User | undefined;
};

export const UserProvider = ({ children, initialUser }: UserProviderProps) => {
  const [user, setUser] = useState<User | undefined>(initialUser);

  return (
    <UserCtx.Provider value={{ user, setUser }}>{children}</UserCtx.Provider>
  );
};

export const useUser = () => {
  const ctx = useContext(UserCtx);

  if (!ctx) throw new Error('User context must be used inside a provider');

  return ctx;
};
