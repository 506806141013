import { socials } from '~/config';

export const Footer = () => (
  <footer className="flex flex-col items-center justify-center py-1">
    <div className="h-1 w-64 bg-light opacity-60 rounded-lg" />
    <a
      href={socials.TWITTER_URL}
      target="_blank"
      rel="noreferrer"
      className="hover:text-gray-300"
    >
      <p className="mt-2">
        Made by
        <span className="font-heading font-bold text-primary mx-2">Ghoste</span>
        <span role="img" className="mr-4" aria-labelledby="ghost">
          &#128123;
        </span>
      </p>
    </a>
  </footer>
);
