import { memo, useEffect, useRef, useState } from 'react';
import { User } from '@supabase/supabase-js';
import { LazyMotion, domAnimation } from 'framer-motion';
import NProgress from 'nprogress';
import { LinksFunction, LoaderFunction, useLoaderData } from 'remix';
import {
  json,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useTransition,
} from 'remix';
import { Footer, Navigation } from '~/components';
import { UserProvider } from '~/ctx/user';
import globalStyles from '~/styles/global.css';
import nProgressStyles from '~/styles/nprogress.css';
import resetStyles from '~/styles/reset.css';
import {
  createSession,
  getTokenFromSession,
  removeSession,
} from '~/utils/session.server';
import { supabase } from '~/utils/supabase';

type LoaderData = {
  user: User | undefined;
};

export const loader: LoaderFunction = async ({ request }) => {
  let token = await getTokenFromSession(request, 'access_token');

  if (!token) return json({ user: undefined });

  const getUser = await supabase.auth.api.getUser(token);
  if (getUser?.user) return json({ user: getUser?.user });

  token = await getTokenFromSession(request, 'access_token');
  if (!token) return json({ user: undefined });

  const setSession = await supabase.auth.setSession(token);

  if (!setSession.session) {
    const headers = await removeSession(request);
    return json({ user: undefined }, headers);
  }

  const headers = await createSession(
    setSession.session.access_token,
    setSession.session.refresh_token,
  );

  return json(
    {
      user: setSession?.session?.user,
    },
    headers,
  );
};

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: resetStyles },
  { rel: 'stylesheet', href: globalStyles },
  { rel: 'stylesheet', href: nProgressStyles },
  {
    rel: 'icon',
    href: 'data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>👻</text></svg>',
  },
];

export default function App() {
  const transition = useTransition();
  const data = useLoaderData<LoaderData>();

  useEffect(() => {
    if (transition.state === 'idle') NProgress.done();
    else NProgress.start();
  }, [transition.state]);

  return (
    <Document>
      <UserProvider initialUser={data.user}>
        <LazyMotion features={domAnimation}>
          <Layout>
            <Outlet />
          </Layout>
        </LazyMotion>
      </UserProvider>
    </Document>
  );
}

function Document({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <RouteChangeAnnouncement />
        <ScrollRestoration />
        <Scripts />
        {process.env.NODE_ENV === 'development' && <LiveReload />}
      </body>
    </html>
  );
}

function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <div id="app">
      <Navigation />
      <main className="container mx-auto pt-4 pb-20 flex-1">{children}</main>
      <Footer />
    </div>
  );
}

export function ErrorBoundary({ error }: { error: Error }) {
  console.error(error);
  return (
    <Document title="Error!">
      <Layout>
        <div>
          <h1>There was an error</h1>
          <p>{error.message}</p>
          <hr />
          <p>
            Hey, developer, you should replace this with what you want your
            users to see.
          </p>
        </div>
      </Layout>
    </Document>
  );
}

/**
 * Provides an alert for screen reader users when the route changes.
 */
const RouteChangeAnnouncement = memo(() => {
  const [hydrated, setHydrated] = useState(false);
  const [innerHtml, setInnerHtml] = useState('');
  const location = useLocation();

  useEffect(() => {
    setHydrated(true);
  }, []);

  const firstRenderRef = useRef(true);
  useEffect(() => {
    // Skip the first render because we don't want an announcement on the
    // initial page load.
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    const pageTitle = location.pathname === '/' ? 'Home page' : document.title;
    setInnerHtml(`Navigated to ${pageTitle}`);
  }, [location.pathname]);

  // Render nothing on the server. The live region provides no value unless
  // scripts are loaded and the browser takes over normal routing.
  if (!hydrated) {
    return null;
  }

  return (
    <div
      aria-live="assertive"
      aria-atomic
      id="route-change-region"
      style={{
        border: '0',
        clipPath: 'inset(100%)',
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: '0',
        position: 'absolute',
        width: '1px',
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
      }}
    >
      {innerHtml}
    </div>
  );
});
